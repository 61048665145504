import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {
  OrderResContentItem,
  getDiceOrderList,
} from '@/common-pages/dice/dice-service';
import DiceItem from '@/common-pages/dice/component/dice-item';
import Ball from '@/common-pages/dice/component/ball';
import globalStore from '@/services/global.state';

const DrawResult = () => {
  const [loading, setLoading] = React.useState(true);
  const [result, setResult] = React.useState<OrderResContentItem>();

  const getList = async () => {
    try {
      setLoading(true);
      const res = await getDiceOrderList({
        orderStatus: '4',
        pageNo: 1,
        pageSize: 1,
      });
      if (res.content) {
        setResult(res.content[0] || {});
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (globalStore.token) {
      getList();
    }
  }, []);

  const isTriple = React.useMemo(() => {
    if (result) {
      return result.bigOrSmall === 0 && result.oddOrEven === 0;
    }
    return true;
  }, [result]);

  return !loading ? (
    <View
      style={[
        theme.padding.lrl,
        theme.padding.tbxxl,
        {backgroundColor: 'var(--card)'},
        theme.borderRadius.m,
        theme.margin.lrl,
        theme.margin.topl,
      ]}>
      <Text fontFamily="fontInterBold" size="medium">
        {result?.issNo} Result:
      </Text>
      <View style={[theme.flex.row, theme.margin.topl, theme.flex.between]}>
        <View style={{gap: theme.paddingSize.xxs}}>
          <Text fontSize={9} fontFamily="fontInterBold" accent>
            RESULT
          </Text>
          <View style={[theme.flex.row]}>
            {result?.result.split(',').map((item, _i) => (
              <DiceItem diceSize={40} key={_i} diceNum={Number(item)} />
            ))}
          </View>
        </View>
        <View style={styles.line} />
        <View style={[theme.flex.center, {gap: theme.paddingSize.xxs}]}>
          <Text fontSize={9} fontFamily="fontInterBold" accent>
            SUM
          </Text>
          <Ball ballSize={40} num={result?.totalCount} isNum fontSize={22} />
        </View>
        <View
          // eslint-disable-next-line react-native/no-inline-styles
          style={[styles.line, isTriple && {backgroundColor: 'transparent'}]}
        />
        {!isTriple ? (
          <>
            <View style={[theme.flex.center, {gap: theme.paddingSize.xxs}]}>
              <Text fontSize={9} fontFamily="fontInterBold" accent>
                DRAW
              </Text>
              <View style={[theme.flex.row, {gap: theme.paddingSize.s}]}>
                {result?.bigOrSmall && result?.bigOrSmall > 0 ? (
                  <Ball
                    ballSize={40}
                    type={result?.bigOrSmall === 2 ? 'small' : 'big'}
                    isNum={false}
                  />
                ) : null}
                {result?.oddOrEven && result?.oddOrEven > 0 ? (
                  <Ball
                    ballSize={40}
                    type={result?.oddOrEven === 1 ? 'odd' : 'even'}
                    isNum={false}
                  />
                ) : null}
              </View>
            </View>
          </>
        ) : null}
      </View>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  line: {
    width: 1,
    backgroundColor: '#E0E2E9',
  },
});

export default DrawResult;
