import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import React from 'react';
import {StyleSheet} from 'react-native';
import LinearGradient from '@/components/basic/linear-gradient';
export interface ResultTabItemProps {
  label: string;
  isSelect?: boolean;
  onSelectKey?: () => void;
}

const ResultTabItem = ({label, onSelectKey, isSelect}: ResultTabItemProps) => {
  return (
    <NativeTouchableOpacity onPress={onSelectKey} style={[theme.flex.flex1]}>
      <LinearGradient
        colors={['var(--card)', 'var(--card)']}
        style={[
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.center,
          theme.borderRadius.xs,
          styles.container,
          // theme.background.white,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderTopColor: isSelect ? theme.basicColor.primary : 'transparent',
          },
        ]}>
        <Text main fontSize={20} fontFamily="fontDinBold">
          {label}
        </Text>
        <Text accent fontFamily="fontDinBold">
          Minutes
        </Text>
      </LinearGradient>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    columnGap: 8,
    borderTopWidth: 3,
  },
});

export default ResultTabItem;
