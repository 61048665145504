import {View} from 'react-native';
import {IGameTopItem} from '../result.type';
import Text from '@/components/basic/text';
import {goTo, toPriceStr} from '@/utils';
import React from 'react';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import globalStore from '@/services/global.state';
import {getGameSource} from '../result-service';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {homePage} from '@/config';

const ListItem = ({
  bonus,
  userPhone,
  gameIcon,
  gameName,
  gameId,
  index = 0,
  disabled = true,
}: IGameTopItem & {index?: number; disabled?: boolean}) => {
  const backgroundColor = React.useMemo(() => {
    return index % 2 === 1 ? '#31343D' : 'var(--image)';
  }, [index]);

  const rank = React.useMemo(() => {
    let textColor = '#fff';
    let bgColors = [];
    let borderColor = '#00000033';
    switch (index) {
      case 0:
        bgColors = ['#E75D16', '#FFC700'];
        break;
      case 1:
        bgColors = ['#4475A2', '#6E8FB6'];
        break;
      case 2:
        bgColors = ['#A27144', '#B6916E'];
        break;
      default:
        textColor = '#000';
        bgColors = ['transparent', 'transparent'];
        borderColor = 'transparent';
        break;
    }
    return (
      <LinearGradient
        colors={bgColors}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            width: 16,
            height: 16,
            borderRadius: 4,
            borderWidth: 1,
            borderColor,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}>
        <Text
          color={textColor}
          fontFamily="fontInterBold"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{includeFontPadding: false, fontSize: 10}}>
          {index + 1}
        </Text>
      </LinearGradient>
    );
  }, [index]);

  const goDetail = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    globalStore.globalLoading.next(true);
    getGameSource(gameId)
      .then(res => {
        // navigateGame(res.gameName, res.gameUrl);
        goTo('WebView', {
          header: true,
          hideAmount: true,
          headerTitle: res.gameName,
          originUrl: res.gameUrl,
        });
      })
      .finally(() => {
        globalStore.globalLoading.next(false);
      });
  };

  return (
    <NativeTouchableOpacity
      disabled={disabled}
      onPress={goDetail}
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.margin.lrl,
        theme.padding.l,
        {backgroundColor, columnGap: theme.paddingSize.s},
      ]}>
      <View
        style={[
          theme.flex.flex1,
          theme.flex.row,
          theme.flex.centerByCol,
          {columnGap: theme.paddingSize.s},
        ]}>
        {rank}
        <LazyImage
          radius={4}
          imageUrl={gameIcon}
          width={theme.iconSize.xxl}
          height={theme.iconSize.xxl}
        />
        <Text size="medium" fontWeight="500">
          {gameName}
        </Text>
      </View>
      <View style={[{width: theme.paddingSize.s * 10}]}>
        <Text size="medium" fontWeight="500" textAlign="center">
          {userPhone}
        </Text>
      </View>
      <View style={[{width: theme.paddingSize.s * 10}]}>
        <Text size="medium" textAlign="right" fontFamily="fontInterBold" main>
          {toPriceStr(bonus)}
        </Text>
      </View>
    </NativeTouchableOpacity>
  );
};

export default ListItem;
