import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import React from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import {getIGameUserRanking} from '../result-service';
import {IGameTopItem} from '../result.type';
import Text from '@/components/basic/text';
import ListItem from '../scratch/list-item';

const Casino = () => {
  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState<IGameTopItem[]>([]);

  const getList = async () => {
    try {
      setLoading(true);
      const res = await getIGameUserRanking(2);
      setList(res);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getList();
  }, []);

  return (
    <Spin
      loading={loading}
      style={[theme.flex.flex1, theme.background.lightGrey]}>
      <ScrollView
        contentContainerStyle={[
          theme.padding.btml,
          list.length === 0 && theme.flex.flex1,
        ]}>
        <View
          style={[
            theme.margin.topl,
            theme.overflow.hidden,
            {
              borderTopLeftRadius: theme.paddingSize.s,
              borderTopRightRadius: theme.paddingSize.s,
            },
          ]}>
          <LinearGradient
            colors={['#282b32', '#282b3200']}
            style={[theme.padding.l, theme.flex.row, theme.flex.centerByCol]}>
            <Text main fontFamily="fontInterBold" size="medium">
              Today's leaderboard
            </Text>
          </LinearGradient>
        </View>
        {!loading && list.length === 0 ? null : (
          <View style={[styles.tableHeader]}>
            <View style={[theme.flex.flex1]}>
              <Text>GAME</Text>
            </View>
            <View style={[{width: 80}]}>
              <Text textAlign="center">USER</Text>
            </View>
            <View style={[{width: 80}]}>
              <Text textAlign="right">BOUNS</Text>
            </View>
          </View>
        )}
        {!loading && list.length === 0 && (
          <View style={[theme.flex.center, theme.flex.flex1]}>
            <NoData />
          </View>
        )}
        {list.map((item, _i) => {
          return <ListItem {...item} index={_i} disabled={false} />;
        })}
      </ScrollView>
    </Spin>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#31343D',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    columnGap: 8,
    flexDirection: 'row',
    marginHorizontal: 12,
  },
});

export default Casino;
