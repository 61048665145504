import Spin from '@/components/basic/spin';
import theme from '@/style';
import {ScrollView, View} from 'react-native';
import ResultTab from '../components/result-tab/result-tab';
import React from 'react';
import {
  DigitDrawResultItem,
  PickGamesContent,
  getQuickPickData,
  getQuickPickResult,
} from '@/common-pages/pick-digit/digit-service';
import Page from '@/components/business/games/page';
import ResultHeader from '@/common-pages/pick-digit/digit-tab/digit-result/result-header';
import ResultItem from '@/common-pages/pick-digit/digit-tab/digit-result/result-item';
import DigitDraw from './draw';

const pageSize = 10;

const QuickDigitResult = ({isActive}: {isActive?: boolean}) => {
  const [loading, setLoading] = React.useState(true);
  const [configId, setConfigId] = React.useState<string>('');
  const [times, setTimes] = React.useState<PickGamesContent[]>([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [list, setList] = React.useState<DigitDrawResultItem[]>([]);
  const [initPage, setInitPage] = React.useState(1);
  React.useEffect(() => {
    getTimes();
  }, []);

  const getTimes = async () => {
    try {
      setLoading(true);
      const res = await getQuickPickData();
      if (res) {
        setTimes(res.pickGames || []);
        if (res.pickGames.length > 0) {
          setConfigId(`${res.pickGames[0].pickThreeID}`);
        }
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const getQuickResult = async (id: number, pageNumber: number = 1) => {
    if (!firstLoad) {
      setLoading(true);
    }
    try {
      if (id) {
        const res = await getQuickPickResult({
          pageNo: pageNumber,
          pickThreeId: id,
          pageSize,
        });
        if (res) {
          console.log('res', res);
          setList(res.drawResultList);
          setTotal(res.totalCount);
        }
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isActive) {
      getTimes();
    }
  }, [isActive]);

  React.useEffect(() => {
    console.log('change configid', configId, isActive);
    if (configId && isActive) {
      setInitPage(1);
      getQuickResult(+configId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId, isActive]);

  return (
    <Spin
      loading={loading}
      style={[theme.flex.flex1, {backgroundColor: 'var(--BG)'}]}>
      <ScrollView stickyHeaderIndices={[1]}>
        {!firstLoad && configId && isActive ? (
          <DigitDraw id={+configId} />
        ) : (
          <View />
        )}
        <ResultTab
          selectKey={configId}
          onSelectKey={setConfigId}
          tabs={times.map(item => {
            return {
              label: `${item.cycle}`,
              key: `${item.pickThreeID}`,
            };
          })}
        />
        {total > 0 && <ResultHeader isQuick />}
        {list.map((item, _i) => (
          <ResultItem
            key={_i}
            issueNum={item.pickNo}
            index={_i}
            result={item.drawResult}
            time={item.drawTime}
          />
        ))}
        {total > 0 ? (
          <Page
            page={initPage}
            total={total}
            pageSize={pageSize}
            onPageChange={p => {
              if (configId) {
                setInitPage(p);
                getQuickResult(+configId, p).then();
              }
            }}
          />
        ) : null}
      </ScrollView>
    </Spin>
  );
};

export default QuickDigitResult;
