import {useWebView} from '@/common-pages/hooks/webview.hooks';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {useRoute} from '@react-navigation/native';
import {goBack} from '@/utils';
import Spin from '@/components/v2/basic/spin';

const Sports = () => {
  const {i18n} = useTranslation();
  const [pageLoading, setPageLoading] = React.useState(true);
  const route = useRoute<any>();
  const {render} = useWebView({
    originUrl: route.params.url,
    onLoadEnd: () => {
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    },
  });
  useEffect(() => {
    setPageLoading(true);
  }, [route.params?.url]);
  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      <DetailNavTitle
        onBack={
          (route.path && route.path.indexOf('index') > -1) ||
          (route.params && !!(route.params as Record<string, string>)?.hideBack)
            ? undefined
            : goBack
        }
        title={route.params?.name || i18n.t('home.tab.sports')}
      />
      <Spin loading={pageLoading}>{render}</Spin>
    </View>
  );
};

export default Sports;
