import {useRoute} from '@react-navigation/native';
import {View, ScrollView} from 'react-native';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack} from '@/utils';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import PageNumber from '@/components/business/games/page';
import Text from '@/components/basic/text';
import {
  LotteryHistoryTabItem,
  LotteryResultHistoryItem,
  getLottoResult,
} from '../../mix-lottery/mix-lottery-service';
import {INDEX_TO_O, RESULT_INDEX} from '../../mix-lottery/constant';
import DigitBall from '../../mix-lottery/component/digit-ball';
import Spin from '@/components/basic/spin';
import dayjs from 'dayjs';
import NoData from '@/components/basic/error-pages/no-data';
import ResultTab from '../../result/components/result-tab/result-tab';
import StateLotteryDraw from '../../result/state-lottery/draw';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {DownIcon} from '@/pages/casino/svg.variable';
import FilterIcon from '@/components/svgs/basic/filer';
import Drawer, {DrawerRef} from '@/components/basic/drawer/drawer';
import FilterPanel from '@/common-pages/result/components/filter-panel';
import globalStore from '@/services/global.state';
import {BasicObject} from '@/types';
const pageSize = 10;
const MixLotteryDetail = ({
  navigation: {setParams},
}: {
  navigation: BasicObject;
}) => {
  const {params = {}} = useRoute();
  const {
    name,
    lotteryID = '',
    isQuick = '0',
    filterList = '[]',
  } = params as {
    name?: string;
    lotteryID?: string;
    isQuick?: string;
    filterList?: string;
  };
  const [list, setList] = React.useState<LotteryResultHistoryItem[]>([]);
  const [lotteryId, setLotteryId] = React.useState(+lotteryID);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const {i18n} = useTranslation();
  const [tab, setTab] = React.useState<LotteryHistoryTabItem[]>([]);
  const [initPage, setInitPage] = React.useState(1);
  const [isQuickGame, setIsQuickGame] = React.useState(isQuick === '1');
  const [lotteryName, setLotteryName] = React.useState(name);
  const drawerRef = React.useRef<DrawerRef>(null);

  const getList = async (id: number, page: number = 1) => {
    try {
      setLoading(true);
      const res = await getLottoResult(id, isQuickGame, page, pageSize);
      if (res) {
        setList(res.rounds || []);
        setTotal(res.totalCount);
        setTab(res.tabs || []);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (lotteryName && lotteryId) {
      getList(lotteryId);
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryId, isQuickGame, lotteryName]);

  const targetList = React.useMemo(() => {
    if (filterList) {
      const res = JSON.parse(filterList);
      if (Array.isArray(res)) {
        return res;
      }
    }
    return [];
  }, [filterList]);

  const target = React.useMemo(() => {
    return targetList.map(item => item.lotteryName).filter(item => item);
  }, [targetList]);

  return (
    <Spin
      style={[theme.fill.fill, theme.background.lightGrey]}
      loading={loading}>
      <DetailNavTitle
        title={lotteryName}
        hideAmount
        hideServer={true}
        rightNode={
          <NativeTouchableOpacity
            onPress={() => drawerRef.current?.open()}
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.padding.lrs,
              theme.padding.tbxxs,
              theme.borderRadius.xs,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                gap: theme.paddingSize.xxs,
                borderWidth: 1,
                borderColor: 'var(--game-line)',
              },
            ]}>
            <FilterIcon color="var(--T2)" />
            <Text size="medium">Filter</Text>
            <View>
              <DownIcon />
            </View>
          </NativeTouchableOpacity>
        }
        onBack={() => goBack()}
      />
      {!loading && list && list.length === 0 ? <NoData /> : null}
      <View style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView
          stickyHeaderIndices={isQuickGame ? [1] : [-1]}
          style={[theme.flex.flex1]}>
          {!loading ? <StateLotteryDraw isQuick={isQuickGame} /> : <View />}
          {isQuickGame && (
            <ResultTab
              selectKey={`${lotteryId}`}
              onSelectKey={k => setLotteryId(+k)}
              tabs={tab.map(item => ({
                key: `${item.lotteryID}`,
                label: item.tabName.split('min')[0],
              }))}
            />
          )}
          {list && list.length > 0 && (
            <View
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.padding.lrl,
                theme.margin.lrl,
                theme.flex.centerByCol,
                !isQuickGame && theme.margin.topl,
                // eslint-disable-next-line react-native/no-inline-styles
                {backgroundColor: 'var(--image)', height: 32},
              ]}>
              <Text>{i18n.t('game-page.label.issue')}</Text>
              <Text>{i18n.t('game-page.label.number')}</Text>
            </View>
          )}
          <View>
            {list.map((item, _i) => {
              const backgroundColor = () => {
                return {
                  backgroundColor: _i % 2 === 0 ? '#31343D' : 'var(--image)',
                };
              };
              return (
                <View
                  key={_i}
                  style={[
                    theme.flex.row,
                    backgroundColor(),
                    theme.padding.l,
                    theme.margin.lrl,
                    theme.flex.centerByCol,
                  ]}>
                  <View style={[theme.flex.flex1]}>
                    <Text color={'var(--T1)'} fontWeight="500" size="medium">
                      {item.roundNo}
                    </Text>
                    {!isQuickGame && (
                      <View style={theme.margin.topxxs}>
                        <Text fontWeight="500">
                          {dayjs(item.drawTime * 1000).format('DD-MM-YYYY')}
                        </Text>
                        <Text fontWeight="500">
                          {dayjs(item.drawTime * 1000).format('hh:mm A')}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View style={[{gap: theme.paddingSize.xxs}]}>
                    {item.results.map((pie, i) => (
                      <View
                        style={[theme.flex.row, theme.flex.between]}
                        key={i}>
                        <Text fontFamily="fontInterBold">
                          {INDEX_TO_O[i + 1]}-prize：
                        </Text>
                        <View
                          style={[
                            theme.flex.row,
                            theme.margin.leftxxs,
                            {gap: theme.paddingSize.xxs},
                          ]}>
                          {pie
                            .split('')
                            .slice(0, 6)
                            .map((ss, _ii) => (
                              <DigitBall
                                key={_ii}
                                disabled={
                                  RESULT_INDEX.slice(
                                    -pie.split('').slice(0, 6).length,
                                  )[_ii] === 'UNUSED'
                                }
                                digit={ss}
                                type={
                                  RESULT_INDEX.slice(
                                    -pie.split('').slice(0, 6).length,
                                  )[_ii]
                                }
                              />
                            ))}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
          </View>
          {total > pageSize ? (
            <PageNumber
              total={total}
              page={initPage}
              pageSize={pageSize}
              onPageChange={p => {
                setInitPage(p);
                getList(lotteryId, p).then();
              }}
            />
          ) : null}
        </ScrollView>
      </View>
      <Drawer ref={drawerRef} mode="top" contentBackgroundColor="#0000">
        <FilterPanel
          list={target}
          game={'State Lottery'}
          onClose={() => drawerRef.current?.close()}
          selectKey={lotteryName}
          onSelect={v => {
            const quick = targetList.find(
              item => item.lotteryName === v,
            )?.isQuick;
            const id = targetList.find(
              item => item.lotteryName === v,
            )?.lotteryID;
            if (globalStore.isWeb) {
              setParams({
                name: v,
                lotteryID: `${id}`,
                isQuick: quick ? '1' : '0',
                filterList,
              });
            }
            setLotteryId(id);
            setIsQuickGame(quick);
            setLotteryName(v);
            drawerRef.current?.close();
          }}
        />
      </Drawer>
    </Spin>
  );
};

export default MixLotteryDetail;
