import {View, ImageBackground, Image, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React, {ReactNode} from 'react';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import theme from '@/style';
import {toPriceStr} from '@/utils';
export interface ResultPanelProps {
  isWin?: boolean;
  winAmount?: number;
  onMore?: () => void;
  betInfo?: string | ReactNode;
  betTime?: string;
  children?: ReactNode | null;
  hideBorder?: boolean;
  hideBet?: boolean;
}

const ResultPanel = ({
  betInfo,
  onMore,
  winAmount,
  children,
  betTime,
  hideBorder,
  hideBet,
}: ResultPanelProps) => {
  return (
    <View
      style={[
        {backgroundColor: 'var(--card)'},
        theme.borderRadius.m,
        theme.margin.l,
        theme.overflow.hidden,
        styles.container,
      ]}>
      {winAmount ? (
        <ImageBackground
          source={require('@components/assets/imgs/result/congratulations.webp')}>
          <View style={[theme.flex.center, theme.padding.tbxxl]}>
            <Text color={'#F15802'} size="medium" fontFamily="fontInterBold">
              Congratulations !
            </Text>
            <Text
              style={[
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  fontWeight: '500',
                  marginTop: 8,
                  marginBottom: 4,
                },
              ]}
              color={'#31373D'}>
              You have won
            </Text>
            <Text color="black" size="large" fontFamily="fontInterBold">
              {toPriceStr(winAmount)}
            </Text>
          </View>
        </ImageBackground>
      ) : (
        <View
          style={[
            theme.flex.center,
            theme.padding.l,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderBottomColor: '#D8E0EA',
              borderBottomWidth: hideBorder ? 0 : 1,
            },
          ]}>
          <Text size="medium" fontFamily="fontInterBold">
            Sorry!
          </Text>
          <Text accent style={[theme.margin.tops]}>
            Better Luck for next Lottery
          </Text>
        </View>
      )}
      {children}
      {!hideBet && (
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.padding.tbs,
            theme.margin.lrl,
            theme.flex.centerByCol,
            styles.bottomContainer,
          ]}>
          {betInfo ? (
            <View>
              <Text size="medium" fontFamily="fontInterBold" main>
                {betInfo}
              </Text>
            </View>
          ) : null}
          <View
            style={[
              theme.flex.flex1,
              theme.flex.row,
              Boolean(betInfo) && theme.flex.center,
            ]}>
            <View>
              <Text accent>Betting Time</Text>
              <Text style={[theme.margin.topxxs]}>{betTime}</Text>
            </View>
          </View>
          <NativeTouchableOpacity
            onPress={onMore}
            style={[
              theme.flex.row,
              theme.border.greyAccent,
              theme.padding.lrs,
              theme.flex.end,
              theme.padding.tbxxs,
              theme.borderRadius.xs,
            ]}>
            <Text>More</Text>
            <View
              style={[
                theme.margin.leftxxs,
                {
                  transform: [
                    {
                      rotate: '-90deg',
                    },
                  ],
                },
              ]}>
              <Image source={downIcon} style={[theme.icon.xs]} />
            </View>
          </NativeTouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 0,
    paddingBottom: 12,
  },
  bottomContainer: {
    borderTopWidth: 1,
    borderTopColor: '#D8E0EA',
    marginTop: 8,
  },
});

export default ResultPanel;
