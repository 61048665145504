import {ColorGetAllResponse, getAll} from '@/common-pages/color/color-service';
import Analyze from '@/common-pages/color/color-tabs/analyze/analyze';
import React from 'react';
import ResultTab from '../components/result-tab/result-tab';
import {ScrollView} from 'react-native';
import ColorDraw from './draw';
import {View} from 'react-native';

const ColorResult = ({isActive}: {isActive?: boolean}) => {
  const [times, setTimes] = React.useState<ColorGetAllResponse[]>([]);
  const [configId, setConfigId] = React.useState<string>();

  const getTimes = async () => {
    try {
      const res = await getAll();
      setTimes(res);
      if (res) {
        setConfigId(`${res[0].id}`);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (isActive) {
      getTimes();
    }
  }, [isActive]);

  return (
    <ScrollView
      stickyHeaderIndices={[1]}
      style={{backgroundColor: 'var(--BG)'}}
      contentContainerStyle={[{minHeight: '100%'}]}>
      {!!configId && isActive ? <ColorDraw /> : <View />}
      <ResultTab
        selectKey={configId}
        onSelectKey={setConfigId}
        tabs={times.map(item => {
          return {
            label: `${item.cycle}`,
            key: `${item.id}`,
          };
        })}
      />
      <Analyze isActive={isActive} configId={+configId!} />
    </ScrollView>
  );
};

export default ColorResult;
