import {View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';

export interface HeaderTabProps {
  options: {label: string; value: string}[];
  selectedKey: string;
  onChange?: (v: string) => void;
}

const HeaderTab = ({options, selectedKey, onChange}: HeaderTabProps) => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        {gap: theme.paddingSize.s},
      ]}>
      {options.map((item, _i) => (
        <NativeTouchableOpacity
          onPress={() => onChange?.(item.value)}
          key={_i}
          style={[
            theme.borderRadius.xs,
            theme.overflow.hidden,
            selectedKey === item.value && styles.itemSelected,
          ]}>
          <View style={[styles.item]}>
            <Text
              size="medium"
              style={[styles.text]}
              main={selectedKey === item.value}
              blod={selectedKey === item.value}>
              {item.label}
            </Text>
          </View>
        </NativeTouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    width: 72,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--card)',
  },
  itemSelected: {
    borderTopWidth: 2,
    borderColor: theme.basicColor.primary,
  },
  text: {
    fontWeight: '500',
  },
});

export default HeaderTab;
