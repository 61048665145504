import {View, ScrollView, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {DownIcon} from '@/pages/casino/svg.variable';
import FilterIcon from '@/components/svgs/basic/filer';
export interface FilterPanelProps {
  list: string[];
  selectKey?: string;
  onSelect?: (v: string) => void;
  game?: string;
  onClose?: () => void;
}

const FilterPanel = ({
  list = [],
  selectKey = 'ALL',
  onSelect,
  game = 'Kerala',
  onClose,
}: FilterPanelProps) => {
  return (
    <View
      style={[theme.background.white, {maxHeight: theme.paddingSize.l * 40}]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          theme.padding.l,
        ]}>
        <Text main size="medium" fontFamily="fontInterBold">
          {game}
        </Text>
        <NativeTouchableOpacity
          onPress={onClose}
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.padding.lrs,
            theme.padding.tbxxs,
            theme.borderRadius.xs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              gap: theme.paddingSize.xxs,
              borderWidth: 1,
              borderColor: '#D8E0EA',
            },
          ]}>
          <FilterIcon />
          <Text size="medium">Filter</Text>
          <View
            style={{
              transform: [
                {
                  rotate: '180deg',
                },
              ],
            }}>
            <DownIcon />
          </View>
        </NativeTouchableOpacity>
      </View>
      <ScrollView
        contentContainerStyle={[
          theme.padding.lrl,
          theme.flex.row,
          theme.flex.wrap,
          {
            columnGap: theme.paddingSize.s,
            paddingBottom: theme.paddingSize.s + theme.paddingSize.l,
          },
        ]}>
        {list.map((item, _i) => (
          <NativeTouchableOpacity
            onPress={() => onSelect?.(item)}
            key={_i}
            style={[
              {height: theme.paddingSize.xxl * 2},
              theme.padding.lrl,
              theme.flex.centerByRow,
              theme.margin.topxxl,
              {borderRadius: theme.paddingSize.xxl},
              selectKey === item ? styles.checkedItem : styles.item,
            ]}>
            <Text
              primary={selectKey === item}
              size="medium"
              // eslint-disable-next-line react-native/no-inline-styles
              style={[{fontWeight: '500'}]}>
              {item}
            </Text>
          </NativeTouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'var(--button-s)',
  },
  checkedItem: {
    borderWidth: 1,
    borderColor: theme.basicColor.primary,
    backgroundColor: 'var(--button-s)',
  },
});

export default FilterPanel;
