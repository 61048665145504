import config from '@/utils/env.config';
import React, {useState} from 'react';
import theme from '@style';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {View} from 'react-native';
import {getDigitType, goBack} from '@/utils';
import globalStore from '@/services/global.state';
import {packageId} from '@/config';
import ResultHeder from './result-header';
import Lottery from './lottery';
import Casino from './casino/casino';
import Drawer, {DrawerRef} from '@/components/basic/drawer/drawer';
import FilterPanel from './components/filter-panel';
import {ROUTERS} from './lottery.07';

const Result = () => {
  const [title] = useState<string>('Result');
  const [currentType, setCurrentType] = React.useState('Lottery');
  const [index, setIndex] = React.useState(0);
  const [back] = useState<number>(
    packageId === 2 && globalStore.channel?.indexOf('B') !== 0 ? 0 : 1,
  );
  const drawerRef = React.useRef<DrawerRef>(null);

  const [selectType, setSelectType] = React.useState('ALL');

  const [digitTypes, setDigitTypes] = React.useState<
    {label: string; value: number}[]
  >([]);

  const targetDigitTypes = React.useMemo(() => {
    const list = digitTypes.map(item => item.label);
    list.unshift('ALL');
    return list;
  }, [digitTypes]);
  const getDigitTypes = async () => {
    const res = await getDigitType();
    setDigitTypes(res || []);
  };
  const targetRoutes = React.useMemo(() => {
    return ROUTERS.filter(item => !item.hide.includes(packageId));
  }, []);

  const showFilter = React.useMemo(() => {
    if (
      currentType === 'Lottery' &&
      ['digit'].includes(targetRoutes[index].type)
    ) {
      return true;
    }
  }, [currentType, index, targetRoutes]);

  React.useEffect(() => {
    if (currentType === 'Lottery') {
      if (targetRoutes[index].type === 'digit') {
        getDigitTypes();
      }
    }
  }, [currentType, index, targetRoutes]);

  return (
    <View style={[theme.fill.fill, theme.flex.col]}>
      {title && (
        <DetailNavTitle
          title={title}
          hideServer={!!back}
          hideAmount={!!config.reactH5Url}
          serverRight
          onBack={back === 1 ? () => goBack() : undefined}
        />
      )}
      <ResultHeder
        onFilter={() => {
          drawerRef.current?.open();
        }}
        showFilter={showFilter}
        currentType={currentType}
        onTypeChange={setCurrentType}
      />
      {currentType === 'Lottery' ? (
        <Lottery
          digitTypes={digitTypes}
          currentIndex={index}
          onChange={setIndex}
          selectType={selectType}
        />
      ) : (
        <Casino />
      )}
      <Drawer ref={drawerRef} mode="top" contentBackgroundColor="#0000">
        <FilterPanel
          list={targetRoutes[index].type === 'digit' ? targetDigitTypes : []}
          game={targetRoutes[index].type === 'digit' ? '3 Digit' : ''}
          onClose={() => drawerRef.current?.close()}
          selectKey={selectType}
          onSelect={v => {
            setSelectType(v);
            drawerRef.current?.close();
          }}
        />
      </Drawer>
    </View>
  );
};

export default Result;
