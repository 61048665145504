import theme from '@/style';
import {TabView} from '@rneui/themed';
import React, {useCallback} from 'react';
import ColorResult from './color/color';
import DiceResult from './dice/dice';
import TabBar from '../transaction/components/transaction-tabBar';
import {View} from 'react-native';
import {packageId} from '@/config';
import {MatkaTypeItem} from './result.type';
import QuickDigitResult from './quick-digit/quick-digit';
import StateLottery from './state-lottery/state-lottery';
import DigitResult from './digit/digit';

export const ROUTERS: {type: string; typeName: string; hide: number[]}[] = [
  {
    type: 'digit',
    typeName: '3 Digits',
    hide: [7],
  },
  {
    type: 'quickDigit',
    typeName: 'Quick 3 Digits',
    hide: [],
  },
  {
    type: 'color',
    typeName: 'Color',
    hide: [],
  },
  {
    type: 'dice',
    typeName: 'Dice',
    hide: [],
  },
  {
    type: 'state-lottery',
    typeName: 'State Lottery',
    hide: [],
  },
];

const Lottery = ({
  currentIndex = 0,
  onChange,
  selectType,
  digitTypes,
}: {
  currentIndex?: number;
  selectType?: string;
  onChange: (v: number) => void;
  matkaType?: MatkaTypeItem[];
  digitTypes?: {label: string; value: number}[];
}) => {
  const targetRoutes = React.useMemo(() => {
    return ROUTERS.filter(item => !item.hide.includes(packageId));
  }, []);
  const hasType = useCallback(
    (type: string) => {
      return targetRoutes.some(item => item.type === type);
    },
    [targetRoutes],
  );
  return (
    <>
      <View style={[theme.background.white]}>
        <TabBar
          value={currentIndex}
          routers={targetRoutes}
          onChange={onChange}
        />
      </View>
      <TabView value={currentIndex} onChange={onChange}>
        {hasType('digit') && (
          <TabView.Item style={[theme.fill.fillW]}>
            <DigitResult
              digitTypes={digitTypes}
              isActive={targetRoutes[currentIndex].type === 'digit'}
              lotteryType={selectType}
            />
          </TabView.Item>
        )}
        {hasType('quickDigit') && (
          <TabView.Item style={[theme.fill.fillW]}>
            <QuickDigitResult
              isActive={targetRoutes[currentIndex].type === 'quickDigit'}
            />
          </TabView.Item>
        )}

        {hasType('color') && (
          <TabView.Item style={[theme.fill.fillW]}>
            <ColorResult
              isActive={targetRoutes[currentIndex].type === 'color'}
            />
          </TabView.Item>
        )}
        {hasType('dice') && (
          <TabView.Item style={[theme.fill.fillW]}>
            <DiceResult isActive={targetRoutes[currentIndex].type === 'dice'} />
          </TabView.Item>
        )}

        {hasType('state-lottery') && (
          <TabView.Item style={[theme.fill.fillW]}>
            <StateLottery
              isActive={targetRoutes[currentIndex].type === 'state-lottery'}
            />
          </TabView.Item>
        )}
      </TabView>
    </>
  );
};

export default Lottery;
